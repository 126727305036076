<template>
  <v-layout>
    <TayoHeader />
    <v-main>
      <v-layout wrap class="d-flex flex-column">
        <NuxtPage :page-key="$route.fullPath" />
      </v-layout>
    </v-main>
  </v-layout>
</template>
